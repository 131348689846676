import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";

const get_ReportProfitStandard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.PROFIT_LOSS.STANDARD, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_ReportProfitPeriode = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.PROFIT_LOSS.PERBANDINGAN, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportProfitMulti = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.PROFIT_LOSS.MULTI, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportProfitKuartal = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.PROFIT_LOSS.KUARTAL, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportProfitProject = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.PROFIT_LOSS.PROJECT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_ReportProfitKuartal,
  get_ReportProfitPeriode,
  get_ReportProfitMulti,
  get_ReportProfitStandard,
  get_ReportProfitProject
}