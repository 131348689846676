<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Laba Rugi Standar</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button class="btn btn-save" :style="{ minWidth: '170px' }" @click="this.showModalRange = true">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff" />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div v-else :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }">
            <loading :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }" :active="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" color="#68caf8"
              height="80" width="80"></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalSingleDate :onPage="true" :show="showModalRange" :type="'ringkasan_buku_besar'" :isMerge="true"
    @hide="setHide()" @update="updateData($event)" :departement="optionDepartement" :isOther="true"
    :parameter="parameterReport"></modalSingleDate>
</template>

<script>
  import SidebarDashboard from "../../../../components/Sidebar.vue";
  import NavbarDashboard from "../../../../components/Navbar.vue";
  import modalSingleDate from "../../components/report/modalSingleDate.vue";
  // import Select2 from "vue3-select2-component";
  import {
    jsPDF
  } from "jspdf";
  import "jspdf-autotable";
  import "../../../../assets/font/poppins-bold.js";
  import "../../../../assets/font/poppins-regular.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Loading from "vue3-loading-overlay";

  import $ from "jquery";
  import {
    get_ReportProfitStandard
  } from "../../../../actions/report/profit";
  import {
    cksClient
  } from "../../../../helper";
  import moment from "moment";
  import "moment/locale/id";
  import {
    get_ListDivisi
  } from "../../../../actions/master";
import { DATA } from '../../../../constans/Url';
  // import { NONE } from "apisauce";

  // import TableLite from 'vue3-table-lite'
  export default {
    name: "dataDepartement",
    components: {
      SidebarDashboard,
      NavbarDashboard,
      // Select2,
      Loading,
      modalSingleDate,
    },
    data() {
      return {
        showModalRange: false,
        fullPage: true,
        isLoading: true,
        thr: "thr",
        bonus: "bonus",
        gaji: "gaji",
        canvas: null,
        optionDepartement: [],
        dataEstimatedAccount: [],
        parameterReport: {
          id_divisi: this.$route.query.id_divisi,
          date: this.$route.query.date,
          merge: this.$route.query.merge,
          isAll: this.$route.query.isAll,
        },
        dataCompany: {
        nama: "",
        divisi: "",
        alamat: "",
        email: "",
        no_telp: "",
        logo: "",
      },
        dataReport: [],
        titleDate: "",
        titleDateCol: "",
        namaReport: "",
      };
    },

    mounted() {
      if (this.parameterReport.date) {
        this.getData();
      }
      // this.tooltip();
      // this.exportPdf();
    },

    created() {
      this.getDivisi();
    },

    methods: {
      getData() {
        var data = {
          ...this.parameterReport
        };
        data.id_company = cksClient().get("_account").id_company;
        this.isLoading = true;
        get_ReportProfitStandard(
          data,
          (res) => {
            const {
              pendapatan,
              pendapatan_lainnya,
              beban_pokok_penjualan,
              beban_operasional,
              beban_lainnya,
              date_start,
              pajak_penghasilan,
              // date_end,
              divisi,
              company
            } = res;
            this.dataCompany = company;
          this.dataCompany.divisi = divisi;
            if (divisi) {
              this.namaReport = divisi;
            } else {
              this.namaReport = cksClient().get("_account").nama_company;
            }
            // this.dataReport = [];
            var data = [];
            data.push({
              nama: "PENDAPATAN",
              padding: 0,
              nilai: "",
              bold: true,
            });
            var jumlah_pendapatan = 0;
            for (const key in pendapatan) {
              if (Object.hasOwnProperty.call(pendapatan, key)) {
                var element = pendapatan[key];
                if (element.sub_level === "1" || element.sub_level === 1) {
                  jumlah_pendapatan += element.nilai ?
                    parseInt(element.nilai) :
                    0;
                  delete element.id;
                  delete element.non_departement;
                }
                if (
                  this.parameterReport.isAll != true &&
                  this.parameterReport.isAll != "true" &&
                  !divisi &&
                  (element.sub_level == "1" || element.sub_level == 1)
                ) {
                  element.color = "#FF3D3D";
                }
                element.padding =
                  1 + parseInt(element.sub_level > 0 ? element.sub_level : 1);
                element.nilai = element.nilai ?
                  this.formatMoney(element.nilai) :
                  0;
                data.push(element);
              }
            }
            data.push({
              nama: "Jumlah Pendapatan",
              padding: 0,
              nilai: this.formatMoney(parseInt(jumlah_pendapatan)),
              bold: true,
            });

            data.push({
              nama: "",
              padding: 0,
              nilai: "",
              bold: true,
            }, {
              nama: "BEBAN USAHA",
              padding: 0,
              nilai: "",
              bold: true,
            });
            var jumlah_beban_pokok = 0;
            for (const key in beban_pokok_penjualan) {
              if (Object.hasOwnProperty.call(beban_pokok_penjualan, key)) {
                var elbeban = beban_pokok_penjualan[key];
                if (elbeban.sub_level === "1" || elbeban.sub_level === 1) {
                  jumlah_beban_pokok += elbeban.nilai ?
                    parseInt(elbeban.nilai) :
                    0;
                  delete elbeban.id;
                  delete elbeban.non_departement;
                }
                if (
                  this.parameterReport.isAll != true &&
                  this.parameterReport.isAll != "true" &&
                  !divisi &&
                  (elbeban.sub_level == "1" || elbeban.sub_level == 1)
                ) {
                  elbeban.color = "#FF3D3D";
                }
                elbeban.padding =
                  1 + parseInt(elbeban.sub_level > 0 ? elbeban.sub_level : 1);
                elbeban.nilai = elbeban.nilai ?
                  this.formatMoney(elbeban.nilai) :
                  0;
                data.push(elbeban);
              }
            }

            data.push({
              nama: "Jumlah Beban Usaha",
              padding: 0,
              nilai: this.formatMoney(parseInt(jumlah_beban_pokok)),
              bold: true,
            }, {
              nama: "",
              padding: "",
              nilai: "",
              bold: true,
            }, {
              nama: "LABA KOTOR",
              padding: 0,
              nilai: this.formatMoney(
                parseInt(jumlah_pendapatan) - parseInt(jumlah_beban_pokok)
              ),
              bold: true,
            }, {
              nama: "",
              padding: "",
              nilai: "",
              bold: true,
            }, {
              nama: "Beban Operasional",
              padding: 0,
              nilai: "",
              bold: true,
            });

            var jumlah_beban_operasional = 0;
            for (const key in beban_operasional) {
              if (Object.hasOwnProperty.call(beban_operasional, key)) {
                var elbebanops = beban_operasional[key];
                if (elbebanops.sub_level === "1" || elbebanops.sub_level === 1) {
                  jumlah_beban_operasional += elbebanops.nilai ?
                    parseInt(elbebanops.nilai) :
                    0;
                  delete elbebanops.id;
                  delete elbebanops.non_departement;
                }
                if (
                  this.parameterReport.isAll != true &&
                  this.parameterReport.isAll != "true" &&
                  !divisi &&
                  (elbebanops.sub_level == "1" || elbebanops.sub_level == 1)
                ) {
                  elbebanops.color = "#FF3D3D";
                }
                elbebanops.padding =
                  1 +
                  parseInt(elbebanops.sub_level > 0 ? elbebanops.sub_level : 1);
                elbebanops.nilai = elbebanops.nilai ?
                  this.formatMoney(elbebanops.nilai) :
                  0;
                data.push(elbebanops);
              }
            }

            data.push({
              nama: "Jumlah Beban Operasional",
              padding: 0,
              nilai: this.formatMoney(jumlah_beban_operasional),
              bold: true,
            }, {
              nama: "",
              padding: "",
              nilai: "",
              bold: true,
            }, {
              nama: "PENDAPATAN OPERASIONAL",
              padding: 0,
              nilai: this.formatMoney(
                parseInt(jumlah_pendapatan) -
                parseInt(jumlah_beban_pokok) -
                parseInt(jumlah_beban_operasional)
              ),
              bold: true,
            }, {
              nama: "",
              padding: "",
              nilai: "",
              bold: true,
            }, {
              nama: "PENDAPATAN DAN BEBAN NON OPERASIONAL",
              padding: 0,
              nilai: "",
              bold: true,
            }, {
              nama: "Pendapatan Non Operasional",
              padding: 2,
              nilai: "",
              bold: true,
            });

            var jumlah_pendapatan_lainnya = 0;
            for (const key in pendapatan_lainnya) {
              if (Object.hasOwnProperty.call(pendapatan_lainnya, key)) {
                var elpen = pendapatan_lainnya[key];
                if (elpen.sub_level === "0" || elpen.sub_level === 0) {
                  jumlah_pendapatan_lainnya += elpen.nilai ?
                    parseInt(elpen.nilai) :
                    0;
                  delete elpen.id;
                  delete elpen.non_departement;
                }
                if (!divisi && (elpen.sub_level == "1" || elpen.sub_level == 1)) {
                  elpen.color = "#FF3D3D";
                }
                elpen.padding =
                  2 + parseInt(elpen.sub_level > 0 ? elpen.sub_level : 1);
                elpen.nilai = elpen.nilai ? this.formatMoney(elpen.nilai) : 0;
                data.push(elpen);
              }
            }

            data.push({
              nama: "Jumlah Pendapatan Non Operasional",
              padding: 2,
              nilai: this.formatMoney(jumlah_pendapatan_lainnya),
              bold: true,
            }, {
              nama: "Beban Non Operasional",
              padding: 2,
              nilai: "",
              bold: true,
            });

            var jumlah_beban_lainnya = 0;
            for (const key in beban_lainnya) {
              if (Object.hasOwnProperty.call(beban_lainnya, key)) {
                var elbebanl = beban_lainnya[key];
                if (elbebanl.sub_level === "1" || elbebanl.sub_level === 1) {
                  jumlah_beban_lainnya += elbebanl.nilai ?
                    parseInt(elbebanl.nilai) :
                    0;
                  delete elbebanl.id;
                  delete elbebanl.non_departement;
                }
                if (
                  this.parameterReport.isAll != true &&
                  this.parameterReport.isAll != "true" &&
                  !divisi &&
                  (elbebanl.sub_level == "1" || elbebanl.sub_level == 1)
                ) {
                  elbebanl.color = "#FF3D3D";
                }
                elbebanl.padding =
                  2 + parseInt(elbebanl.sub_level > 0 ? elbebanl.sub_level : 1);
                elbebanl.nilai = elbebanl.nilai ?
                  this.formatMoney(elbebanl.nilai) :
                  0;
                data.push(elbebanl);
              }
            }

            var laba =
              parseInt(jumlah_pendapatan) -
              parseInt(jumlah_beban_pokok) -
              parseInt(jumlah_beban_operasional) +
              parseInt(jumlah_pendapatan_lainnya) -
              parseInt(jumlah_beban_lainnya);

            var jumlahPajakPenghasilan = 0;
            for (const key in pajak_penghasilan) {
              if (Object.hasOwnProperty.call(pajak_penghasilan, key)) {
                var elpenghasilan = pajak_penghasilan[key];
                if (
                  elpenghasilan.sub_level === "1" ||
                  elpenghasilan.sub_level === 1
                ) {
                  jumlahPajakPenghasilan += elpenghasilan.nilai ?
                    parseInt(elpenghasilan.nilai) :
                    0;
                }
              }
            }

            data.push({
              nama: "Jumlah Beban Non Operasional",
              padding: 2,
              nilai: this.formatMoney(jumlah_beban_lainnya),
              bold: true,
            }, {
              nama: "Jumlah Pendapatan dan Beban Non Operasional",
              padding: 0,
              nilai: this.formatMoney(
                jumlah_pendapatan_lainnya + jumlah_beban_lainnya
              ),
              bold: true,
            }, {
              nama: "",
              padding: 0,
              nilai: "",
              bold: true,
            }, {
              nama: "LABA BERSIH (Sebelum Pajak)",
              padding: 0,
              nilai: this.formatMoney(laba),
              bold: true,
            }, {
              nama: "Jumlah Pajak Penghasilan",
              padding: 0,
              nilai: this.formatMoney(jumlahPajakPenghasilan),
              // bold: true,
            }, {
              nama: "LABA BERSIH (Setelah Pajak)",
              padding: 0,
              nilai: this.formatMoney(laba - jumlahPajakPenghasilan),
              bold: true,
            });

            this.dataReport = data;
            this.convertDate(date_start);
            this.isLoading = false;
            this.generatePdf();
          },
          (err) => {
            console.log(err);
          }
        );
      },

      setHide() {
        this.showModalRange = false;
      },

      updateData(event) {
        this.parameterReport = event;
        this.getData();
      },

      getDivisi() {
        get_ListDivisi({
            id_company: cksClient().get("_account").id_company,
          },
          (res) => {
            this.optionDepartement = res.list;
          }
        );
      },
      convertDate(date_start) {
        var start = moment(date_start);
        // var end = moment(date_end);
        // if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        //   this.titleDate = `Per Tgl. ${start.format("DD")} s/d ${end.format(
        //     "DD MMM YYYY"
        //   )}`;
        //   this.titleDateCol = `${start.format("DD")} s/d ${end.format(
        //     "DD MMM YYYY"
        //   )}`;
        // } else if (
        //   start.format("YYYY") != end.format("YYYY") &&
        //   start.isValid()
        // ) {
        //   this.titleDate = `Per Tgl. ${start.format("DD MMM")} s/d ${end.format(
        //     "DD MMM YYYY"
        //   )}`;
        //   this.titleDateCol = `${start.format("DD MMM")} s/d ${end.format(
        //     "DD MMM YYYY"
        //   )}`;
        // } else {
        this.titleDate = `Per Tgl. ${start.format("DD MMM YYYY")}`;
        this.titleDateCol = `${start.format("DD MMM YYYY")}`;
        // }
      },
      formatMoney(a) {
        if (!a) {
          return 0;
        }
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

      async generatePdf() {
        const doc = new jsPDF("p", "mm", "a4");
        var header = "/img/bg-header.png";
        doc.addImage(header, "png", 220, -35, 220, 2500 / 72, null, null, 180);
        var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
        doc.addImage(logo, "png", 13, 6, 20, 20, null, null, 0);

        doc.setFont("Poppins-Bold").setFontSize(13);
        doc.setTextColor(255, 255, 255);
        doc.text(37, 15, `${this.dataCompany.nama}`, {
          align: "left",
        });
        doc.setFont("Poppins-Regular").setFontSize(10);
        doc.setTextColor(255, 255, 255);
        doc.text(37, 22, `${this.dataCompany.divisi ? this.dataCompany.divisi :  this.dataCompany.email}`, {
          align: "left",
        });

        doc.setFontSize(9);
        doc.setFont("Poppins-Regular");
        doc.splitTextToSize(
          this.dataCompany.alamat, 10)

          const [array1, array2] = this.splitTextIntoTwoArrays(this.dataCompany.alamat, 2)
        doc.text(200, 10, array1.join(" "), {
          align: "right",
        });
        doc.text(200, 16, array2.join(" "), {
          align: "right",
        });
        doc.text(200, 22, this.dataCompany.no_telp, {
          align: "right",
        });
        doc.text(200, 28, this.dataCompany.divisi ? this.dataCompany.email : '', {
          align: "right",
        });
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        // doc.text(105, 45, this.namaReport, "center");
        doc.setFontSize(16);
        doc.text(105, 45, `Laba/Rugi (Standar)`, {
          align: "center",
        });
        doc.setProperties({
          title: `Laba/Rugi (Standar)`,
        });
        doc.setFontSize(10);
        doc.text(105, 53, this.titleDate, "center");
        var blankTable = {
          columns: [{
            title: "",
            dataKey: "id",
          }, ],
          rows: [{
            id: ""
          }, ],
        };

        doc.autoTable(blankTable.columns, blankTable.rows, {
          margin: {
            top: 45,
            bottom: 80,
          },
          theme: "plain",
        });
        var tableData = {
          columns: [{
              title: "",
              dataKey: "nama"
            },
            {
              title: "",
              dataKey: "nilai"
            },
          ],
          rows: this.dataReport,
        };
        doc.autoTable(tableData.columns, tableData.rows, {
          showHead: "everyPage",
          didParseCell: function (data) {
            if (data.column.index === 0) {
              var padding = !data.row.raw.padding ?
                1 :
                parseInt(data.row.raw.padding);
              data.cell.styles.cellPadding = {
                left: 3 * padding
              };
            }

            if (data.row.raw.bold && data.column.index === 0) {
              data.cell.styles.fontStyle = "bold";
            }

            if (data.row.section === "head") {
              data.cell.styles.textColor = "#324C8F";
              if (data.column.dataKey === "nilai") {
                data.cell.styles.halign = "right";
              }
            }

            if (data.row.raw.color) {
              data.cell.styles.textColor = data.row.raw.color;
            }
          },

          columnStyles: {
            0: {
              cellWidth: 80
            },
            1: {
              halign: "right"
            },
          },
          margin: {
            top: 10,
            bottom: 60
          },
          theme: "plain",
        });

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.internal.getNumberOfPages();
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);

          //Print Page 1 of 4 for example

          var img = new Image();
          img.src = "img/line.png";
          doc.addImage(img, "png", 190, 250, 170, 1 / 72, null, null, 180);

          doc.text(105, 257, "Finanta Accounting System Report", "center");

          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            210 - 20,
            315 - 30,
            null,
            null,
            "right"
          );

          doc.text(
            `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
            210 - 192,
            315 - 30,
            null,
            null,
            "left"
          );
        }

        setTimeout(() => {
          var iframe = document.getElementById("pdfFrame");
          iframe.src = doc.output("bloburi", {
            filename: `Laba/Rugi ${this.namaReport} (Standar)`,
          });
        }, 500);
      },

      tooltip() {
        $('[data-toggle="tooltip"]').tooltip();
      },

      detail(payroll) {
        const getPayroll = payroll;

        if (getPayroll == "bonus") {
          this.$router.push("/data-payroll/detail-bonus");
        }
        if (getPayroll == "gaji") {
          this.$router.push("/data-payroll/detail-gaji");
        }
        if (getPayroll == "thr") {
          this.$router.push("/data-payroll/detail-thr");
        }

        $('[data-toggle="tooltip"]').tooltip("hide");
      },
    },
  };
</script>

<style scoped>
  .card-table {
    margin-top: 0px !important;
  }

  .btn-gaji {
    background-color: #68caf8;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-bonus {
    background-color: #f8ce3a;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-thr {
    background-color: #ff5252;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .dropdown-menu {
    background: transparent !important;
    box-shadow: none !important;
    width: 102px !important;
    min-width: unset;
  }

  .dropdown-item:nth-child(2) {
    margin-top: 6px;
  }

  .dropdown-item:nth-child(3) {
    margin-top: 6px;
  }

  iframe {
    z-index: 1000;
    width: 100%;
    height: 800px;
  }
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>